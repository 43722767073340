<template>
  <els-drawer class="rules-design" :visible.sync="isVisible" :showHeader="false">
    <div class="rd--content" v-if="isVisible">
      <els-flow-chart ref="elsFlowChartRef" :itemsData="itemsData" :graphData="graphInitData" addNodeModel="drag"  :useDefaultNodes="false" @submit="handleSubmit" :hooks="graphHooks">
        <els-button-group
          class="fd--header"
          :column="buttons"
          :delimiter="true"
        ></els-button-group>
      </els-flow-chart>
    </div>
    <els-form-dialog class="rd--debug-dialog" :visible.sync="dialogVisible" submit="测试" width="100%" title="调试">
      <els-form :elForm="{'labelPosition':'top'}" :column='debugFormColumn'></els-form>
    </els-form-dialog>
    <!--  -->
    <el-dialog :visible.sync="funTestVisible" append-to-body fullscreen class="el-dialog--fun-test" title="测试脚本基础功能">
      <div class="dialog--fun-test" v-if="funTestVisible">
        <fun-test :formElem="funTestElem" :context="context" v-bind="funTestConfig" @close="closeFunTest"></fun-test>
      </div>
    </el-dialog>
  </els-drawer>
</template>

<script>
import {dicts,nodeGroup,nodeListStyle,nodeModels,eventTableColumns,debugFormColumn,buildFormElem,formElemSort,transformMetadata,elemFunTestConfig} from "./config.js"

import funTest from "./funTest.vue"
import elsFlowChart from "@/components/els-flow-chart/main.vue";
import elsFormDialog from "@/components/els-form-dialog/main.vue"
import elsForm from "@/components/els-form/main.vue"
//
export default {
  name: "rules-design",
  components: { elsFlowChart ,elsFormDialog,elsForm,funTest },
  props: {
    nodes: Array,
    visible:Boolean,
    getEventList:Function,
    currentRow:Object,
    metadata:Object,
  },
  data: () => ({
    isVisible:false,
    dialogVisible:false,
    funTestVisible:false,
    funTestAttrName:'',
    funTestValue:'',
    funTestElem:undefined,
  }),
  computed: {
    context(){
      return this;
    },
    itemsData() {
      const that = this;
      const list = this.nodes;
      if (!list || list.length == 0) return {};
      //
      const getEventList = this.getEventList;
      const eventLib = dicts.EVENT_TYPES
      //
      const nodes = [];
      const forms = {
        input:[],
        edge:[{label:'标签',name:'data',column:[
          {tag:'el-select',prop:'label',label:'边的名称',multiple:true,hooks:{created(){
            const model = this.context.elsFlowChart.currentItem.getModel();
            if(model.relationTypes){
              const cls = model.relationTypes.map(v => ({value:v}))
              this.$('cls',cls)
            }
          },
          // input(v){if(Array.isArray(v)){this.$('value',v.join('/'))}},
          // context(ctx){if(typeof this.$('value') == 'string'){ctx.props.value = this.$('value').split('/')}}
          }}
        ]}]
      };
      //
      Object.keys(nodeGroup).forEach(gn => {
        const gt = nodeGroup[gn]
        const ns = list.filter(item => item.type === gn)
        const ds = []
        ns.forEach(item => {
          const n = gn + '.' +item.name;
          const cn = item.configurationDescriptor.nodeDefinition
          const cnd = cn.defaultConfiguration
          const isIn = cn.inEnabled;
          const isOut = cn.outEnabled;
          const relationTypes = cn.relationTypes
          // console.log('===================',item,n,cn,cnd)
          ds.push({
            ...nodeListStyle[gn],
            name:item.clazz,
            label:item.name,
            model:{
              ...nodeModels[gn],
              name:item.clazz,
              label:item.name,
              links: { left: {input:isIn,output:false}, right: {input:false,output:isOut} },
              relationTypes,
            }
          })


          const form = {
            label:'详情',
            name:'data',
            column:[
              // { label: "节点类名", prop: "name", value:item.clazz, disabled:true},
              // { label: "节点名称", prop: "label", value:item.name,disabled:true},
              // { label: "节点标签", prop: "description"},
              {cls:'是否启用调试模式',prop:'debugMode',tag:'el-checkbox'},
              {prop:'configuration',tag:'div',cls:[
                // ...Object.keys(cnd).map(k => ({prop:k,label:k,value:typeof cnd[k] == 'string' ? cnd[k] : ''})),
                ...formElemSort(Object.keys(cnd),n).map(k => (buildFormElem(n,k,cnd[k],cnd,that))),
              ]},
              {label:'描述',prop:'additionalInfo.description',type:'textarea'}
            ]
          }
          const desc = cn.description;
          const details = cn.details;
          const events = {
            label:'事件',
            name:'event',
            column:[
              {tag:'div',style:{width:'100%',height:'100%',position:'relative',display:'flex','flex-direction':'column'},hooks:{
                mounted(){
                  if(!this.context.elsFlowChart.currentItemModel.key){
                    this.$('vif',false)
                  }
                }
              },cls:[
                {tag:'div', class:'rules-design--node-event-operate',cls:[
                {tag:'el-select',width:'150px',prop:'eventName',label:'事件类型',value:'DEBUG_RULE_NODE',cls:[...eventLib],hooks:{
                updated(){
                  this.context.source.elsTableDiv && this.context.source.elsTableDiv.resetTable(this.$('value'))
                }
              }},
              {tag:'el-date-picker',prop:'eventTimes',label:'时间范围',type:'datetimerange',pickerOptions:{shortcuts:[
                {text:'最近一天',onClick(picker){
                  const end = new Date()
                  const start = new Date(end.getTime() - 3600 * 1000 * 24)
                  picker.$emit('pick', [start, end]);
                }},
                {text:'今天',onClick(picker){
                  const d = new Date();
                  const y = d.getFullYear();
                  const m = d.getMonth();
                  const t = d.getDate();
                  //
                  const start = new Date(y,m,t)
                  const end = new Date(start.getTime() + 3600 * 1000 * 24)
                  picker.$emit('pick', [start, end]);
                }}
              ]},
              hooks:{
                created(){
                  this.context.source.eventTimes = this;
                  this.refreshTime = () => {
                    const end = new Date()
                    const start = new Date(end.getTime() - 3600 * 1000 * 24)
                    this.handleInput([start,end])
                  }
                  //

                },
                mounted(){
                  this.refreshTime();
                },
                updated(){
                  this.context.source.elsTableDiv && this.context.source.elsTableDiv.apiOnload();
                }
                // updated(){
                //   this.refreshTime();
                //   // this.context.source.elsTableDiv && this.context.source.elsTableDiv.apiOnload();
                // }
              }
              },
              {tag:'div',cls:[
                {tag:'el-button',size:'mini',circle:true,icon:'el-icon-refresh',hooks:{context(ctx){ctx.on = {click:() => {this.context.source.eventTimes.refreshTime() || this.context.source.elsTableDiv.apiOnload()}}}}}
              ]}
              ]},

              {tag:'el-divider'},
              {tag:'div',style:'flex:1',hooks:{
                created(){
                  this.context.source.elsTableDiv = this;
                  this.onload = async () => {
                    const key = this.context.elsFlowChart.currentItemModel.key
                    if(!key){return {}}
                    const id = key.id.id;
                    const n = this.context.source.formData.eventName;
                    const ts = (this.context.source.formData.eventTimes || []).map(d => d.getTime());
                    const params = {startTime:ts[0],endTime:ts[1]}
                    const list = await getEventList(id,n,params);
                    return {list,total:list.length}
                  }
                  this.apiOnload = () => {
                    // console.log('-------------api onload---------',this.context.source.elsTable)
                    this.context.source.elsTable && this.context.source.elsTable.$refs.elsTable.apiOnload();
                  }
                  this.resetTable = (s) => {
                  const column = eventTableColumns[s] || [];
                  this.$('cls',[{tag:'els-table',ref:'elsTable',key:new Date().getTime(),column,showMenu:false,onload:this.onload,hooks:{created(){this.context.source.elsTable = this;}}}])
                }},
                mounted(){
                  const v = this.context.source.formData.eventName;
                  this.resetTable(v)
                }
              }},
              ]}


            ]
          }
          const help = {
            label:'帮助',
            name:'help',
            column:[
              {tag:'div',cls:[
                {tag:'h3',cls:n},
                {tag:'p',cls:desc}
              ]},
              {tag:'el-divider'},
              {tag:'div',cls:[
                {tag:'p',domProps:{innerHTML:details}}
              ]}
            ]
          }
          forms[item.clazz] = [form,events,help]

        })
        //
        nodes.push({
          title:gt,
          name:gn,
          data:ds
        })

      });
      // console.log(forms)
      return {nodes,forms}
    },
    graphHooks(){
      return {
        'node:dblclick':function(evt){
          const item = evt.item;
          this.apiSetCurrentItem(item,true);
        },
        'edge:dblclick':function(evt){
          const item = evt.item;
          this.apiSetCurrentItem(item,true);
        },
        'node:click':function(evt){},
        'link-point:mousedown':function (evt,graph,edge){
          const model = evt.item.getModel();
          const relationTypes = model.relationTypes || []
          edge.relationTypes = relationTypes;
          return false;
        }
      }
    },
    buttons() {
      const btn0 = {
        key: "close",
        icon: "el-icon-d-arrow-right",
        tooltip: { placement: "top", content: "收起抽屉" },
        handle:this.handleCloseDrawer
      };
      return [btn0];
    },
    debugFormColumn(){
      return debugFormColumn;
    },
    graphInitData(){
      if(this.metadata){

        return transformMetadata(this.metadata,this.nodes)
      }
      return [];
    },
    funTestConfig(){
      const n = this.funTestAttrName;
      if(n){
        let res = elemFunTestConfig
        n.split('.').forEach(k => {
          res = res[k]
        })
        return {...res,script:this.funTestValue};
      }
      return {}
    },
  },
  methods:{
    handleCloseDrawer(){this.isVisible = false;},
    handleSubmit(d){
      const data = this.buildFormData(d)
      //
      this.$emit('save',data,d);
    },
    //
    handleFunTestSave(v){},
    //
    buildFormData(d){
      const [{ nodes = [], edges = [] }, form] = d;
      // 筛选 初始节点
      const esids = edges.map(e => e.source)
      const etids = edges.map(e => e.target)
      const nsid = esids.filter(id => !etids.includes(id))
      const nids = nodes.map(n => n.id)
      const firstNodeIndex = nsid.length > 0 ? nids.indexOf(nsid[0]) : 0;
      //
      const nodeIds = []
      //
      const ruleChainId = this.currentRow.sourceId

      const ruleChainConnections = []
      //
      const data = {connections:[],nodes:[],ruleChainId,firstNodeIndex,ruleChainConnections}
      //
      nodes.forEach((node) => {
        const {id,x,y,label,key = {}} = node;
        const {name:clazz,description,debugMode = false,configuration = {}} = (form[id].data || {})
        //
        const additionalInfo = {layoutX:Math.round(x),layoutY:Math.round(y)}
        //
        // console.log('===> ',key)
        const nd = {
          ...key,
          additionalInfo,
          configuration,
          debugMode,
          // id:{entityType: "RULE_NODE",id:key},
          name:description || label,
          // ruleChainId,
          type:clazz
        }
        //
        nodeIds.push(id)
        data.nodes.push(nd)
      })
      //
      edges.forEach(edge => {
        const {label = [],source,target} = edge;
        //
        const fromIndex = nodeIds.indexOf(source)
        const toIndex = nodeIds.indexOf(target)
        //
        data.connections.push(...label.map(type => ({fromIndex,toIndex,type})))
      })
      //
      return data;
    },
    // api
    showFunTest(n,v,e){this.funTestAttrName = n;this.funTestVisible = true;this.funTestValue = v;this.funTestElem = e;},
    closeFunTest(){this.funTestVisible = false;this.funTestAttrName = '';this.funTestElem = undefined;}
  },
  watch:{
    visible(v){
      this.isVisible = v;
    },
    isVisible(v) {
      if (v !== this.visible) {
        this.$emit("update:visible", v);
      }
      if (v) {
        this.$nextTick(() => {
          this.$refs["elsFlowChartRef"].apiResetSize();
        });
      }else{
        this.dialogVisible = false;
      }
    },
  },
  mounted(){
    console.log(this.itemsData)
  }
};
</script>

<style lang="scss" scoped>
.rules-design{

  .rd--content{
    width:100%;
    height:100%;
    .fd--header{
      // margin-left:24px;
    }
  }
}
</style>

<style lang="scss" >
.tip{
  color:#aaa;
  font-size:12px;
  font-style: normal;
  font-weight: normal;
  display: block;
}
.els-form{
  .inline{

    display:inline-block;
    margin-right:16px;
  }
}
.els-form--item{
  margin:20px 0;
  .editor--tip{
    // margin-left:40px;
    color:#aaa;
    padding:5px 0;
  }
  .els-monaco-editor{
    height:120px;
    border:1px solid #ddd;
  }

}
.rules-design--node-event-operate{
  display:flex;
  margin-top:1em;
  justify-content:space-between;
  //
  .el-form-item{
    display: flex;

    margin-bottom:0 !important;
    label{margin-right:1em;}

  }
}
.el-dialog--fun-test{
  .el-dialog{
    display:flex;
    flex-direction: column;
    .el-dialog__body{
      flex:1;
    }
  }
}
.dialog--fun-test{
  width:100%;
  height:100%;
  // overflow: auto;
}
.message-content{
  min-height: 40px;
  max-height: 500px;
  overflow:auto;
}
.el-message-box{
  width:auto;
}
</style>
