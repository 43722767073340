<template>
  <el-dialog
    class="self-style--mike"
    :title="title"
    :visible.sync="dialogVisible"
    :before-close="handleBeforeClose"
    :width="width"
    :close-on-click-modal="false"
    :show-close="showClose"
    append-to-body
    destroy-on-close
  >
    <template v-slot:title>
      <div>{{ title }}</div>
    </template>
    <div class="m--content self-dialog">
      <div class="c--body d--body">
        <div class="b--title" v-if="showFormTitle">
          <span class="t--text">{{ formTitle || title }}</span>
        </div>
        <div class="b--main">
          <slot></slot>
        </div>
      </div>
    </div>
    <template v-slot:footer  v-if="showFooter">
      <div class="c--footer d--footer">
        <el-button
          size="normal"
          class="btn-normal"
          :loading="loading"
          @click="handleColseDialog"
          >{{cancel }}</el-button
        >
        <slot name="submit">
          <el-button
            size="normal"
            class="btn-normal"
            type="primary"
            :loading="loading"
            @click.stop="handleFormSubmit"
            >{{ submit }}</el-button
          >
        </slot>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "els-form-dialog",
  props: {
    title: String,
    formTitle: String,
    cancel: { type: String, default: "取消" },
    submit: { type: String, default: "确定" },
    width: { type: String, default: "500px" },
    loading: { type: Boolean, default: false },
    showFormTitle: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: true },
    showClose: { type: Boolean, default: true },
    submitFunction: Function,
    visible: Boolean,
    beforeClose: Function,
  },
  data() {
    return {
   //   lang: this.$t("ai"),
      dialogVisible: false,
    };
  },
  methods: {
    handleFormSubmit() {
      if (this.submitFunction) {
        let promise = this.submitFunction();
        if (promise) {
          promise.then(() => {
            this.dialogVisible = false;
          });
        }
      } else {
        this.$emit("submit");
      }
    },
    handleBeforeClose(done) {
      if (this.beforeClose) {
        this.beforeClose();
      }
      done();
      this.$emit("cancel");
    },
    handleColseDialog() {
      if (this.beforeClose) {
        this.beforeClose();
      }
      this.dialogVisible = false;
      this.$emit("cancel");
    },
    /* api */
    apiOpenDialog() {
      this.dialogVisible = true;
    },
    apiCloseDialog() {
      this.dialogVisible = false;
    },
  },
  watch: {
    dialogVisible() {
      this.$emit("update:visible", this.dialogVisible);
    },
    visible() {
      if (!this.visible && this.dialogVisible && this.beforeClose) {
        this.beforeClose();
      }
      this.dialogVisible = this.visible;
    },
  },
  mounted(){
    this.dialogVisible = this.visible;
  }
};
</script>

<style scoped lang="scss">
.btn-normal {
  width: 106px;
  margin-left: 16px;
}
.el-select {
  width: 100%;
}
.self-style--mike {
  ::v-deep .el-dialog {
    // min-width: 780px;
    .el-dialog__header {
      color: #333;
      padding: 13px 16px;
      .el-dialog__headerbtn {
        top: 11px;
      }
    }
    .el-dialog__body {
      padding: 24px 4px;
      color: #333;
      .self-dialog {
        width: 100%;
        border-radius: 8px;

        .d--body {
          .b--title {
            margin-bottom: 16px;
            .t--text {
              border-left: 4px solid rgba(31, 115, 224, 1);
              padding-left: 0.5em;
            }
          }
          .b--main {
            padding: 0 86px;
            max-height: calc(80vh - 200px);
            overflow: auto;
          }
        }
        .d--footer {
          padding: 1em;
          border-top: 1px solid rgba(19, 31, 61, 0.1);
        }
      }
    }
  }
  //
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
    z-index: 9999;
  }
  ::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: #00000020;
    border-radius: 5px;
    z-index: 9999;
    &:hover {
      background: #00000040;
    }
  }
}
</style>
